import React from "react"
import { Link } from "gatsby"
import Header from "../../components/services/servicesHeader"
import SEO from "../../components/seo"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"

import Background from "../../../assets/images/servicesHeader/development_icon.svg"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import development from "../../../assets/images/servicesPages/development.png"

export default () => (
    <div id="development-page">
        <SEO title={' ICON Worldwide | Software Development'} 
        description="We build Blockchain wallet applications, complex scientific AI algorithms, and cloud based applications: discover ICON's software development services now!"
        canonical={'https://icon-worldwide.com/services/software-development'}/>
        <Header headerTitle="Software Development" headerSubTitle="Blockchain, AI and Machine Learning"  headerBackground={Background}/>
        <div id="servicesPagesInner">
            <div className="winningTeam">
                <div className="text-info">
                    <p>The once future potential of Blockchain, Artificial Intelligence and MachineLearning 
                    have now become widely accepted as having the potential to transform businesses across 
                    all industries. The financial services industry is exploding with FinTech innovation. 
                    Pharmaceuticals are exploring AI and Machine Learning for diagnostics.
                    Manufacturers are excited to begin moving supply chain operations onto the blockchain.
                    </p>
                   <p><h3>We build Blockchain wallet applications, complex scientific algorithms with AI potential,
                    and cloud based applications for pharmaceutical companies.</h3></p> 
                    <p>We are happy to participate in an <a href="https://www.designrush.com/agency/software-development/trends/rfp-software-development" target="_blank">rfp for software development</a></p>
                    <p><a href="https://www.appdeveloperlisting.com/" target="_blank">Top Software Development Companies Of 2022 according to DesignRush</a></p>
                </div>
            </div>
            <img className="arrowDown" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <div className="normal-image">
                <img src={development} alt="software development, ICON Worldwide" title="ICON Worldwide, software development"/>
            </div>
            <div className="techologies">
                <div className="techologies-inner">
                <h2>Languages</h2>
                    <ul>
                        <li><h3>HTML</h3></li>
                        <li><h3>CSS</h3></li>
                        <li><h3>JAVASCRIPT</h3></li>
                        <li><h3>PHP</h3></li>
                        <li><h3>SWIFT</h3></li>
                        <li><h3>GO</h3></li>
                        <li><h3>JAVA</h3></li>
                        <li><h3>SQL</h3></li>
                        <li><h3>C#</h3></li>
                        <li><h3>APEX</h3></li>
                        <li><h3>C++</h3></li>
                        <li><h3>PYTHON</h3></li>
                        <li><h3>RUBY</h3></li>
                    </ul>
                </div>
            </div>
            <div className="techologies">
                <div className="techologies-inner">
                    <h2>Technologies</h2>
                    <ul>
                        <li><h3>ETHEREUM</h3></li>
                        <li><h3>BLOCKCHAIN</h3></li>
                        <li><h3>GOOGLE APIS</h3></li>
                        <li><h3>PLAY</h3></li>
                        <li><h3>HEROKU</h3></li>
                        <li><h3>AWS</h3></li>
                        <li><h3>SALESFORCE</h3></li>
                        <li><h3>INFORMATICA</h3></li>
                        <li><h3>DOCKER</h3></li>
                        <li><h3>GRADLE</h3></li>
                        <li><h3>SBT</h3></li>
                        <li><h3>MAVEN</h3></li>
                        <li><h3>JENKINS</h3></li>
                        <li><h3>GIT</h3></li>
                        <li><h3>JSP</h3></li>
                        <li><h3>SVN</h3></li>
                        <li><h3>SAP UI5</h3></li>
                        <li><h3>SYMPHONY</h3></li>
                    </ul>
                </div>
            </div>
                <div className="yellowLine"></div>
            <ServicesBottomNav/>
        </div>
        <Footer noScrollbar="true"/>
    </div>
)